import React from 'react'

import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SectionBanner from '../components/SectionBanner'
import ContactInfo from '../components/Contact/ContactInfo'
import ContactForm from '../components/Contact/ContactForm'
import ReactMapGL from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import SEO from '../components/SEO'

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const mapboxAccessToken = process.env.GATSBY_MAPBOX_ACCESS_TOKEN


const Contact = ({ data }) => {

  const components = data.contentfulPage.components
  const bannerData = components[1]
  const { lon, lat } = data.contentfulCompanyInfo.location

  const mapCoords = {
    lon: parseFloat(lon),
    lat: parseFloat(lat)
  }

  const [viewport, setViewport] = React.useState({
    latitude: mapCoords.lat,
    longitude: mapCoords.lon,
    zoom: 11
  });


  return (
    <Layout>
      <SEO title="VM Construction | Contact Us" description="Contact us today for an estimation. Whether it’s an emergency or scheduled project, VM construction is there for you every step of the way."/>
      <SectionBanner data={bannerData} />
      <div className="bg-gray-darkest lg:px-20">
        <div className="flex flex-wrap justify-center py-10">
          <div>

            <ContactInfo data={components[0]} />
          </div>
          <div className="">
            <ReactMapGL
              {...viewport}
              width="100%"
              height="100%"
              onViewportChange={(viewport) => setViewport(viewport)}
              mapboxApiAccessToken={mapboxAccessToken}>
              </ReactMapGL>
              
           
             
            <div className="text-white">
              <div className="text-sm font-rubik uppercase font-medium py-6">We cover all of Northamptonshire.</div>

            </div>
          </div>

        </div>
      </div>
      <div className="flex flex-col items-center justify-center  py-8 bg-gray-darkest">


        <ContactForm title="GET IN TOUCH TODAY" />
      </div>

    </Layout>
  );



}

export const query = graphql`
query Contact {
    contentfulPage(contentful_id: {eq: "4Wn7M5TChZFypBZZoRbfiC"}) {
    id
    title
    description
    components {
      ... on ContentfulContentModules {
        title
        subtitle
        componentImage {
          gatsbyImageData(quality: 90, width: 800)
        }
        childContentfulContentModulesParagraphTextNode {
          childMarkdownRemark {
            html
          }
        }
      }
      ... on ContentfulBanner {
        title
        subtitle
        bannerImage {
          gatsbyImageData(quality: 90, width: 1200)
        }
      }
    }
  }
  contentfulCompanyInfo {
    location {
      lon
      lat
    }
  }
}
  `

export default Contact