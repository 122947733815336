import React from 'react'




const ContactInfo = ({ data }) => {

    const { title, childContentfulContentModulesParagraphTextNode: { childMarkdownRemark: { html } } } = data


    return (
        <>
            <div className="flex flex-wrap justify-center px-10">
                <div className="w-full md:px-4 text-center xl:text-left justify-center">
                    <h3 className="font-rubik  text-2xl font-bold text-gray-light md:pt-16 uppercase">{title}</h3>
                    <div className="font-roboto py-4 xl:max-w-xl text-gray-lightest" dangerouslySetInnerHTML={{ __html: html }}></div>
                </div>
            </div>
        </>
    )
}

export default ContactInfo